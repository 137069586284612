import Vue from 'vue'
import store from '@/store'
import { auth } from '@/firebase'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  /* ---------------- ROTAS PÚBLICAS ---------------- */
  {
    path: '*',
    name: 'Não Encontrado',
    component: () => import(/*webpackChunkName: "inicio" */'../views/Public/Home.vue'),
  },
  {
    path: '/',
    name: 'Inicio',
    component: () => import(/*webpackChunkName: "inicio" */'../views/Public/Home.vue'),
  },
  {
    path: '/contato',
    name: 'Contato',
    component: () => import(/*webpackChunkName: "inicio" */'../views/Public/Home.vue'),
  },
  {
    path: '/produtos/:id?',
    name: 'Produtos', 
    meta: { header: true },
    component: () => import(/*webpackChunkName: "produtos" */'../views/Public/Products.vue'),
  },
  {
    path: '/produto/:id',
    name: 'Produto',
    component: () => import(/*webpackChunkName: "produto" */'../views/Public/Product.vue'),
  },
  {
    path: '/suporte',
    name: 'Suporte',
    meta: { header: true },
    component: () => import(/*webpackChunkName: "suporte" */'../views/Public/Support.vue'),
  },
  {
    path: '/downloads',
    name: 'Downloads',
    meta: { header: true },
    component: () => import(/*webpackChunkName: "downloads" */'../views/Public/Downloads.vue'),
  },
  {
    path: '/aprenda',
    name: 'Aprenda',
    meta: { header: true },
    component: () => import(/*webpackChunkName: "aprenda" */'../views/Public/LearnIt.vue'),
  },
  {
    path: '/termos',
    name: 'Termos de Condições e Serviço',
    meta: { header: true },
    component: () => import(/*webpackChunkName: "termos" */'../views/Public/Terms.vue'),
  },
  {
    path: '/privacidade',
    name: 'Política Privacidade',
    meta: { header: true },
    component: () => import(/*webpackChunkName: "privacidade" */'../views/Public/Privacy.vue'),
  },
  {
    path: '/carrinho',
    name: 'Carrinho',
    meta: { header: true },
    component: () => import(/*webpackChunkName: "carrinho" */'../views/Client/Cart.vue'),
  },
  /* ---------------- ROTAS LOGADAS ----------------- */
  {
    path: '/conta',
    name: 'Minha Conta',
    meta: { header: true, requiresAuth: true },
    component: () => import(/*webpackChunkName: "conta" */'../views/Client/Account.vue'),
  },
  /* ------------- ROTAS ADMINISTRATIVAS ------------ */
  {
    path: '/admin/painel',
    name: 'Painel Administrativo',
    meta: { header: true, requiresAuth: true, requiresAdmin: true },
    component: () => import(/*webpackChunkName: "painel" */'../views/Admin/Dashboard.vue'),
  },
  {
    path: '/admin/produtos',
    name: 'Painel: Produtos',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: () => import(/*webpackChunkName: "painel-produtos" */'../views/Admin/Products.vue'),
  },
  {
    path: '/admin/categorias',
    name: 'Painel: Categorias',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: () => import(/*webpackChunkName: "painel-categorias" */'../views/Admin/Categories.vue'),
  },
  {
    path: '/admin/clientes',
    name: 'Painel: Clientes',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: () => import(/*webpackChunkName: "painel-clientes" */'../views/Admin/Clients.vue'),
  },
  {
    path: '/admin/faq',
    name: 'Painel: FAQ',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: () => import(/*webpackChunkName: "painel-faq" */'../views/Admin/Faq.vue'),
  },
  {
    path: '/admin/pedidos/:id?',
    name: 'Painel: Pedidos',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: () => import(/*webpackChunkName: "painel-pedidos" */'../views/Admin/Orders.vue'),
  },
  {
    path: '/admin/aprenda',
    name: 'Painel: Aprenda',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: () => import(/*webpackChunkName: "painel-aprenda" */'../views/Admin/LearnIt.vue'),
  },
  {
    path: '/admin/downloads',
    name: 'Painel: Downloads',
    meta: { requiresAuth: true, requiresAdmin: true },
    component: () => import(/*webpackChunkName: "painel-downloads" */'../views/Admin/Downloads.vue'),
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() { document.getElementById('app').scrollIntoView() }
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin)

  if (requiresAdmin && store.state.user.Permission !== 'Admin') {
    next('/')
  }

  if (requiresAuth && !auth.currentUser) {
    store.commit('setNext', to.path)
    store.commit('setModal', 'login')
    
    if (from.path === '/') { next('/') }
    
  } else { next() }
})

export default router
