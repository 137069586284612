import Vue from 'vue'
import Vuex from 'vuex'
import router from '@/router'
import { auth, usersColl } from '@/firebase'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: {},
    next: '',
    loading: false,
  },
  mutations: {
    setUser(state, payload) { state.user = payload },
    setNext(state, payload) { state.next = payload },
    setLoading(state, payload) { state.loading = payload }
  },
  actions: {    
    async logout({ commit }) {
      if (!this.state.loading) commit('setLoading', true)

      await auth.signOut()
      .then(() => {
        commit("setUser", {})
        this._vm.$toast.success('Deslogado, até breve')
        if(router.currentRoute.path !== '/') { router.push('/') }
      })
      .catch(() => this._vm.$toast.error('Erro, tente novamente'))

      if (this.state.loading) commit('setLoading', false)
    },
      
    async fetchUserProfile({ commit }, user) {
      if (!this.state.loading) commit('setLoading', true)
      
      await usersColl.doc(user.uid).get()
      .then(async (result) => {
        
        commit('setUser', result.data())

        if (this.state.next !== '') {
          router.push(this.state.next)
          commit('setNext', '')
        } 
      })
      .catch(() => this._vm.$toast.error('Falha ao carregar, tente novamente'))

      if (this.state.loading) commit('setLoading', false)
    }
  }
})
