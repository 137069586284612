import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'
import 'firebase/auth'

import axios from 'axios'

var firebaseConfig = {
  apiKey: 'AIzaSyBhFWH5YNMeZGeBo8YMpNKqKwb4zIfK3sQ',
  authDomain: 'vw-solucoes.firebaseapp.com',
  projectId: 'vw-solucoes',
  storageBucket: 'vw-solucoes.appspot.com',
  messagingSenderId: '231800407552',
  appId: '1:231800407552:web:5fb2ce258131ec13567ae5',
  measurementId: 'G-6E4235TG09'
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

const auth = firebase.auth()
const db = firebase.firestore()
const storageRef = firebase.storage().ref()
const GoogleProvider = new firebase.auth.GoogleAuthProvider()

const faqsColl = db.collection('Faqs')
const usersColl = db.collection('Users')
const ordersColl = db.collection('Orders')
const listingsColl = db.collection('Listings')
const productsColl = db.collection('Products')

function validateDocument(val = '') {

  const formatted = val.replace(/(\.|-|\/)/gm, '')
  
  if (formatted.length === 11) {
    let aux = 11 - (formatted.substr(0, 9).split('').reduce((ac, cr, i) => ac + (cr *(10 - i)), 0) % 11) 
    let digit = aux > 9 ? 0 : aux
    if (digit != formatted[9]) { return false }

    aux = 11 - (formatted.substr(0, 10).split('').reduce((ac, cr, i) => ac + (cr *(11 - i)), 0) % 11)
    digit = aux > 9 ? 0 : aux
    if (digit != formatted[10]) { return false }

    return true 

  } else
  if (formatted.length === 14) {
    const factor = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    let aux = 11 - (formatted.substr(0, 12).split('').reduce((ac, cr, i) => ac + (cr * factor[i + 1]), 0) % 11)
    let digit = aux > 9 ? 0 : aux
    if (digit != formatted[12]) { return false }

    aux = 11 - (formatted.substr(0, 13).split('').reduce((ac, cr, i) => ac + (cr * factor[i]), 0) % 11)
    digit = aux > 9 ? 0 : aux
    if (digit != formatted[13]) { return false }

    return true 
  }

  return false
}

async function getPeople(doc) {

  if (doc.length !== 18) { return }
  
  return new Promise((resolve, reject) => {
    axios.get('https://brasilapi.com.br/api/cnpj/v1/' + doc.replace(/\.|\/|-/g, '')).then(result => resolve({
      name: result.data.razao_social,
      nameFantasia: result.data.nome_fantasia,
      address: {
        state: result.data.uf,
        postcode: result.data.cep,
        number: result.data.numero,
        city: result.data.municipio,
        neighborhood: result.data.bairro,
        complement: result.data.complemento,
        street: result.data.descricao_tipo_logradouro + ' ' + result.data.logradouro,
      }
    })).catch(err => reject(err))
  })
}

async function getAddress(cep) {

  if (cep.length !== 9) { return }

  return new Promise((resolve, reject) => {
    axios.get('https://brasilapi.com.br/api/cep/v1/' + cep.replace('-', '')).then(result => resolve({
      city: result.data.city, 
      state: result.data.state, 
      street: result.data.street, 
      neighborhood: result.data.neighborhood
    })).catch(err => reject(err))
  })
}

export {
  db,
  auth,
  firebase,
  storageRef,
  GoogleProvider,
  faqsColl,
  usersColl,
  ordersColl,
  listingsColl,
  productsColl,
  getPeople,
  getAddress,
  validateDocument,
};
