<template>
  <div id="app">
    <App>
      <router-view />
      <Login v-if="!currentUser" />
      <Complete v-if="currentUser" />
      <Register v-if="!currentUser" />
      <Loading v-if="$store.state.loading" />
      <!-- Cookies -->
      <t-modal name="cookies" class="z-45 bottom-10">
        <p class="sm:mt-1 flex items-center font-semibold antialiased"><svg class="mr-1.5 h-4 w-4" preserveAspectRatio="xMidYMid meet" viewBox="0 0 24 24"><g transform="rotate(90 12 12)"><path fill="currentColor" d="M11.984 22C6.472 21.994 2.006 17.52 2 12c.393.111.8.168 1.208.169a4.101 4.101 0 0 0 3.394-1.757c.692-1 .838-2.281.39-3.412c.287.051.577.077.868.078A4.257 4.257 0 0 0 11.2 5.5a4.166 4.166 0 0 0 .849-3.5c5.514.018 9.969 4.51 9.95 10.032c-.017 5.523-4.501 9.986-10.015 9.968Zm.761-3.432a1.249 1.249 0 0 0 1.639-.672a1.252 1.252 0 0 0-.269-1.362a1.249 1.249 0 0 0-2.104.635a1.25 1.25 0 0 0 .735 1.399Zm-5.833-1.693a1.664 1.664 0 0 0 2.17-.91a1.669 1.669 0 0 0-1.539-2.3a1.667 1.667 0 0 0-1.663 1.668a1.67 1.67 0 0 0 1.012 1.536h.016l.004.006Zm9.416-2.507a1.662 1.662 0 0 0 1.916-.463a1.668 1.668 0 0 0-.342-2.453a1.662 1.662 0 0 0-2.595 1.426c.017.625.38 1.187.943 1.458h-.012l.03.013l.02.008h-.006a.43.43 0 0 1 .042.016l.004-.005Zm-4.344-4.035a.833.833 0 1 0 .323.066l-.018-.008l-.027-.01a.822.822 0 0 0-.278-.048Zm3.744-4.166a1.246 1.246 0 0 0-.254 2.466a1.247 1.247 0 0 0 .739-2.366h-.01a1.24 1.24 0 0 0-.475-.1ZM4.496 9.5a.832.832 0 1 1 .001-1.665a.832.832 0 0 1-.001 1.665ZM3.248 7a1.249 1.249 0 1 1 .002-2.498A1.249 1.249 0 0 1 3.248 7ZM8.24 5.333a1.249 1.249 0 1 1 .002-2.5a1.249 1.249 0 0 1-.002 2.501v-.001ZM5.329 3.667a.832.832 0 1 1 0-1.665a.832.832 0 0 1 0 1.665Z"/></g></svg>Controle sua privacidade</p>
        <p class="my-1 pr-2 text-subtitle">Nosso site utiliza cookies para melhorar a navegação.</p>
        <div class="my-2 sm:my-1 flex space-x-2 text-xs">
          <router-link @click.native="$modal.hide('cookies')" to="/termos" target="blank" class="text-primary whitespace-nowrap">Termos de uso</router-link>
          <span>|</span> 
          <router-link @click.native="$modal.hide('cookies')" to="/privacidade" target="blank" class="text-primary whitespace-nowrap">Políticas de privacidade</router-link>
        </div>
        <button @click="$modal.hide('cookies')" type="button" class="mt-2 w-full px-4 py-2 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-primary hover:bg-primarydark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary">Continuar</button>
      </t-modal>
    </App>
  </div>
</template>

<script>
import { auth } from '@/firebase'

export default {
  components: {
    App: () => import('@/layout/App'),
    Login: () => import('@/components/auth/login'),
    Complete: () => import('@/components/auth/complete'),
    Register: () => import('@/components/auth/register'),
    Loading: () => import('@/components/loading/ellipsis'),
  },

  data() { return { currentUser: auth.currentUser } },

  async mounted() { 
    await new Promise(resolve => setTimeout(resolve, 1000))
    if (!localStorage.getItem('consentCookies')) { 
      localStorage.setItem('consentCookies', true)
      this.$modal.show('cookies')
    }
  }
}
</script>